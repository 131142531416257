<template>
  <v-select
    v-model="selected"
    :options="options"
    :filterable="false"
    :placeholder="placeholder"
    :selectable="item => !item.disabled"
    :reduce="reduce"
    @open="onOpen"
    @close="onClose"
    @search="debounceSearch"
  >
    <template #list-footer>
      <li
        v-show="hasNextPage"
        ref="load"
        class="p-2 text-center flex justify-center items-center gap-2"
      >
        <b-spinner
          small
          variant="secondary"
        />
        <span>Loading more options...</span>
      </li>
    </template>
  </v-select>
</template>

<script>
/**
 * Select component with infinite scroll capability.
 *
 * ## Props
 * @prop {Object | String | Number} value - The value of the selected item.
 * @prop {Array} items - The list of items available for selection.
 * @prop {String} placeholder - A short guidance text displayed in the input field.
 * @prop {Boolean} hasNextPage - Indicates whether there are more pages of data available for loading.
 * @prop {Number} debounceTimeout - Delay time (in milliseconds) for search functionality.
 * @prop {Function} reduce - A function that transforms the input value before returning it.
 *
 * ## Events
 * @event input - Triggered when a user selects an item.
 * @param {Object | String | Number} payload - The selected item.
 *
 * @event on-search - Triggered when a user uses the search functionality of vue-select.
 * @param {String} query - The search query entered by the user.
 *
 * @event on-intersect - Triggered when the scroll position reaches the bottom of the container.
 *
 * @event on-close - Triggered when the container closed.
 */

import vSelect from 'vue-select'
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'InfiniteSelect',
  components: {
    vSelect,
    BSpinner,
  },
  props: {
    value: {
      type: [Object, String, Number],
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Pilih item',
    },
    hasNextPage: {
      type: Boolean,
      default: false,
      required: true,
    },
    debounceTimeout: {
      type: Number,
      default: 250,
      required: false,
    },
    reduce: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    selected: null,
    observer: null,
    debounceSearch: null,
  }),
  watch: {
    selected(newValue) {
      this.$emit('input', newValue)
    },
  },
  created() {
    this.debounceSearch = _.debounce(
      q => this.$emit('on-search', q),
      this.debounceTimeout,
    )
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async onOpen() {
      await this.$nextTick()
      this.observer.observe(this.$refs.load)
    },
    onClose() {
      this.observer.disconnect()
      this.$emit('on-close')
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting && this.hasNextPage) {
        const ul = target.offsetParent
        if (!ul) {
          this.observer.disconnect()
          return
        }
        const { scrollTop } = ul
        this.$emit('on-intersect')
        await this.$nextTick
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>
