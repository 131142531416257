import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { numberOnly } from '@/libs/helpers'
import InfiniteSelect from '@/views/components/select/InfiniteSelect.vue'
import { toastError } from '@/libs/toastification'
import modal from '../modal/modalSuccess.vue'
import { submissionTable, fieldBarangRusak } from '../tableconfig'

export default {
  components: {
    modal,
    InfiniteSelect,
  },
  data() {
    return {
      fieldBarangRusak,
      fields: submissionTable,
      selectAll: false,
      selected: [],
      barangRusak: [],
      loading: false,
      memo: '',
      disabledKey: true,
      error: 'Minimal 10 karakter',
      numberOnly,
      partnerOptions: [],
      hasNextPartners: false,
      limitPartners: 50,
      offsetPartners: 0,
      searchTermPartners: '',
    }
  },
  mounted() {
    this.$store.commit('brokenItemSubmission/RESET_PRODUCTS')
    this.partnerSelected = ''
    this.getPartners()
  },
  computed: {
    ...mapFields('brokenItemSubmission', ['partnerSelected']),
    ...mapGetters('brokenItemSubmission', ['dataProductsMod']),
  },
  methods: {
    /**
     * Searches for partners based on the given query.
     * If the query is empty, resets the partner selection.
     * Otherwise, fetches partners matching the query.
     *
     * @param {string} q - The search query for partners.
     */
    searchPartners(q) {
      if (!q) {
        this.resetSelectionPartner()
      }
      this.getPartners(q)
    },
    /**
     * Fetches a list of partners from the server.
     *
     * @param {string} q - The search query to filter partners.
     * @returns {void}
     */
    getPartners(q) {
      if (q) {
        this.offsetPartners = 0
        this.searchTermPartners = q
      }
      this.$http_kompack.get('v1/kompack/outbound/non-pickup/partners', {
        params: {
          limit: this.limitPartners,
          offset: this.offsetPartners,
          search: this.searchTermPartners,
        },
      }).then(response => {
        const { data } = response.data
        this.hasNextPartners = data.length >= this.limitPartners
        const mappedData = data.map(item => (item.outbound_queue === 0
          ? {
            label: item.name,
            value: item.id,
            disabled: false,
          } : {
            label: `${item.name} (Masih ada antrian)`,
            value: item.id,
            disabled: true,
          })) //  || this.offsetPartners <= this.limitPartners
        if (this.offsetPartners === 0) {
          this.partnerOptions = mappedData
          this.offsetPartners += this.limitPartners
          return
        }
        this.partnerOptions.push(...mappedData)
        this.offsetPartners += this.limitPartners
      }).catch(() => {
        toastError('Gagal mengambil daftar partner. Silakan coba lagi nanti.')
        this.hasNextPartners = false
      })
    },
    /**
     * Resets the partner selection by clearing the search term and resetting the offset.
     */
    resetSelectionPartner() {
      this.searchTermPartners = ''
      this.offsetPartners = 0
    },
    async selectPartner() {
      this.loading = true
      this.selected = []
      await this.$store
        .dispatch('brokenItemSubmission/getListProductByPartner')
        .then(() => { })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text:
                'Unable to load the table data. Please try again later or contact support.',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    showModalAjukan() {
      const nonVariantFiltered = this.selected.filter(product => (product.is_variant === 0 && product.total > 0))
      const variantFiltered = this.selected.filter(product => (product.is_variant === 1)).map(product => ({
        ...product,
        variants: product.variants.filter(item => item.total > 0),
      }
      ))

      this.barangRusak = [...nonVariantFiltered, ...variantFiltered]
      this.$bvModal.show('modal-barang-rusak')
    },
    submission() {
      const data = {
        products: this.barangRusak,
        memo: this.memo,
      }
      this.$store.dispatch('brokenItemSubmission/submission', data)
        .then(() => {
          this.$bvModal.show('modal-success-broken-item-submission')
        })
        .catch(err => {
          if (err?.products[0] === 'The products field is required.') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: 'Jumlah pengajuan kosong',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            })
          }
        })
    },
    addQty(isVariant, indexRow, indexVariant) {
      if (isVariant === 1) {
        this.dataProductsMod[indexRow].variants[indexVariant].total += 1
      } else {
        this.dataProductsMod[indexRow].total += 1
      }
      this.disabledKey = false
    },
    minQty(isVariant, indexRow, indexVariant) {
      if (isVariant === 1) {
        this.dataProductsMod[indexRow].variants[indexVariant].total -= 1
      } else {
        this.dataProductsMod[indexRow].total -= 1
      }
      this.disabledKey = false
    },
    checkedAll() {
      if (this.selectAll) {
        this.selected = this.dataProductsMod
      } else {
        this.selected = []
      }
    },
    handleCheckbox() {
      if (this.selected.length === this.dataProductsMod.length) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },
    onCloseModal() {
      this.$bvModal.hide('modal-success-broken-item-submission')
      this.$router.push('/outbound/pengeluaran-barang')
    },
    checkData() {
      const nonVariantFiltered = this.selected.filter(product => (product.is_variant === 0 && product.total > 0))
      const variantFiltered = this.selected.filter(product => (product.is_variant === 1)).map(product => (product.variants.filter(item => item.total > 0)))

      if (this.selected.length === 0) return true
      if ((nonVariantFiltered?.length + variantFiltered?.length) === this.selected.length && variantFiltered.every(data => data.length > 0)) return false
      if ((variantFiltered?.length) === this.selected.length && variantFiltered.every(data => data.length > 0)) return false
      if (nonVariantFiltered?.length > 0 && (nonVariantFiltered?.length) === this.selected.length) return false

      return true
    },
    formatTextArea(value) {
      if (value.length >= 256) {
        this.error = 'Maksimal 255 karakter'
        return value.slice(0, 256)
      }
      if (value.length < 10) {
        this.error = 'Minimal 10 karakter'
      } else {
        this.error = null
      }
      return value
    },
    limitToStock(isVariant, total, stock, index, varIndex) {
      if (isVariant === 1) {
        this.dataProductsMod[index].variants[varIndex].total = total > stock ? stock : total
      } else {
        this.dataProductsMod[index].total = total > stock ? stock : total
      }
      this.disabledKey = false
    },
  },
}
